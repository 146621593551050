<template>
  <v-app>
    <v-app class="web-container"
      :class="{'container' : this.$route.name !== 'home' &&
      this.$route.name !== 'login' &&
      this.$route.name !== 'signup' &&
      this.$route.name !== 'TermsAndConditions' &&
      this.$route.name !== 'PrivacyPolicy' &&
      this.$route.name !== 'Faq' &&
      this.$route.name !== 'About' &&
      this.$route.name !== 'PressRoom' &&
      this.$route.name !== 'ContactUs'}">
      <Header />
      <v-main class="app-outer-container" v-bind:style= "[isAppMobile ? { padding: '0px'}: isToken?{padding: '90px 0px 0px'}: { padding: '0px'}]">
        <router-view />
      </v-main>
      <div v-if="$route.meta.subheader === 'number-tabs'" class="subheader-wrapper">
        <NumberTabs :headerDetails="tabs" />
      </div>
      <div v-if="viewFooter">
        <Footer />
      </div>
    </v-app>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';
import { isMobile } from 'mobile-device-detect';
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import Vue from 'vue';
import VueCookies from 'vue-cookies';
// import Banner from '@/components/SearchHotels/Banner.vue';
// import TermsAndConditions from '@/views//TermsAndConditions.vue';
Vue.use(VueCookies);
export default {
  name: 'App',
  data() {
    return {
      viewFooter: false,
      hasSubHeader: false,
      subHeader: '',
      tabs: [],
      isMobile: false,
      isAppMobile: window.innerWidth <= 768,
      isToken: false,
    };
  },
  components: {
    Header,
    Footer,
    // Banner,
    // TermsAndConditions,
  },
  mounted() {
    this.isMobile = isMobile; // to get is mobile
    this.setHeaderAccess(this.$route.name);
    this.detectOS();
  },
  computed: {
    ...mapGetters({
      bookingSteps: 'bookingSteps',
    }),
  },
  watch: {
    $route(to) {
      this.setHeaderAccess(to.name);
      this.tabs = this.tabs.map((tab) => {
        if (tab.link === to.name) {
          tab.activeTab = true;
          return tab;
        }
        tab.activeTab = false;
        return tab;
      });
    },
    bookingSteps(steps) {
      const confirm = this.tabs.filter(tab => tab.link === 'confirm')[0];
      const payment = this.tabs.filter(tab => tab.link === 'payment')[0];
      const guests = this.tabs.filter(tab => tab.link === 'guests')[0];

      if (steps.confirm === 'complete') {
        confirm.successTab = true;
      }

      if (steps.payment === 'complete') {
        payment.successTab = true;
      }

      if (steps.guests === 'complete') {
        guests.successTab = true;
      }

      if (steps.confirm === 'pending') {
        confirm.successTab = false;
      }

      if (steps.payment === 'pending') {
        payment.successTab = false;
      }

      if (steps.guests === 'pending') {
        guests.successTab = false;
      }
      this.tabs = [confirm, guests, payment];
    },
  },
  created() {
    const checkIsIOS = () => /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    if (checkIsIOS()) {
      this.disableIosTextFieldZoom();
    }
    this.tabs = this.getBookHotelTabs();
    const isLoggedIn = this.$cookies.get('isLoggedIn');
    if (isLoggedIn !== 'true') {
      this.$cookies.set('isLoggedIn', false);
    }
  },
  methods: {
    setHeaderAccess(name) {
      if (name === 'hotels' || name === 'view-hotel') {
        this.viewFooter = true;
      } else {
        this.viewFooter = false;
      }
      const accessToken = Vue.$cookies.get('token');
      if (accessToken) {
        this.isToken = true;
      } else {
        this.isToken = false;
      }
    },
    getBookHotelTabs() {
      return [
        {
          number: 1,
          label: 'Confirm',
          link: 'confirm',
          activeTab: false,
          successTab: false,
        },
        {
          number: 2,
          label: 'Guests',
          link: 'guests',
          activeTab: false,
          successTab: false,
        },
        {
          number: 3,
          label: 'Payment',
          link: 'payment',
          activeTab: false,
          successTab: false,
        },
      ];
    },
    disableIosTextFieldZoom() {
      const el = document.querySelector('meta[name=viewport]');
      if (el !== null) {
        let content = el.getAttribute('content');
        const re = /maximum\-scale=[0-9\.]+/g; // eslint-disable-line
        if (re.test(content)) {
          content = content.replace(re, 'maximum-scale=1.0');
        } else {
          content = [content, 'maximum-scale=1.0'].join(', ');
        }
        el.setAttribute('content', content);
      }
    },
    	   detectOS() {
      const { userAgent } = window.navigator;

      if (userAgent.includes('Win')) {
        this.$cookies.set('device', 'Windows');
      } else if (userAgent.includes('Mac')) {
        this.$cookies.set('device', 'macOS');
      } else if (userAgent.includes('Linux')) {
        this.$cookies.set('device', 'Linux');
      } else if (userAgent.includes('Android')) {
        this.$cookies.set('device', 'Android(WEB)');
      } else if (userAgent.includes('iPhone')) {
        this.$cookies.set('device', 'iPhone(WEB)');
	  } else {
        this.$cookies.set('device', 'Unknown');
	  }
    },
  },
};
</script>
<style lang="scss">
@media only screen and (max-width: 768px) {
  #app {
    .subheader-wrapper {
      top: 80px !important; // height of the header is set to 56 in Header.vue use that as reference
    }
  }
}
#app {
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  // height: 100%;
  .subheader-wrapper {
    position: absolute;
    top: 56px; // height of the header is set to 56 in Header.vue use that as reference
    width: 100%;
    left: 0;
    right: 0;
  }
}
html,
body {
  font-family: Abel, sans-serif;
  font-size: 16px !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;
}
html,
body,
.theme--light.v-application {
  background: $background-color !important;
  font-family: Abel, sans-serif;
  color: #080914;
}

.tf-app {
 width: 100%;
  margin: 0 auto;
  padding: 56px 30px 0 30px !important;
  // min-width: 1200px !important;
  // max-width: 1200px !important;
}

.mobile-device-container {
  width: 100%;
  height: 100%;
  .tf-logo {
    margin: 0 auto;
    max-height: 150px;
    max-width: 300px;
  }
  .mobile-device-label {
    text-align: center;
    margin: 30px 0;
  }
  .playstore-icons {
    display: flex;
    justify-content: center;
    padding: 0 1rem 5rem 0;
  }
  .tearms-and-condition-container  {
    padding: 2.9rem 1rem;
  }
}

// responsiveness design
@media only screen and (max-width: 768px) {
  .tf-app {
    padding: 113px 10px 0 10px !important;
  }
}
</style>
