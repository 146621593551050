<template>
  <div class="hotel-card-container pointer hover-hotel-list">
    <div v-if="cardStyle" class="display-hotel-map">
      <v-card width="360" height="150" color="#fff" theme="dark" class="rounded-card">
        <div class="display-card" @mouseover="handleHover">
          <div class="photo-map">
            <v-img
              class="hotel-image-map"
              v-if="hotelsImage"
              height="150px"
              width="160px"
              :style="{ borderRadius: '10px', marginRight: '10px' }"
              :src="hotelsImage[0]"
            ></v-img>
          </div>
          <div>
            <div class="hotel-heading-map">{{ hotelName }}</div>
            <div class="single-rating-text-map">
              {{ hotel.rating }}<v-icon color="#f0f0f0" size="18">mdi-star</v-icon>
            </div>
            <!-- <div class="d-flex">
          <v-rating
            v-model="hotel.rating"
            color="#FFC338"
            hover
            half-increments
            density="compact"
            background-color="#FFC338"
            size="large"
          ></v-rating>
          <div class="rating-text-map">{{ hotel.rating }}</div>
        </div> -->
            <div v-if="areRoomsAvailable && !!currency" class="hotel-rate-container-map">
              <span class="hotel-rate-map"
                >From
                <span>
                  {{ currency.shortName
                  }}{{
                    hotel.minimumPrice
                      ? Math.ceil(hotel.minimumPrice * currency.exchangeRate)
                      : hotel.minimumPrice
                  }}</span
                ></span
              >
              <span class="overnight-rate"
                ><strike>
                  {{ currency.shortName
                  }}{{
                    hotel.minimumOverNightPrice
                      ? Math.ceil(hotel.minimumOverNightPrice * currency.exchangeRate)
                      : hotel.minimumOverNightPrice
                  }}
                </strike>
                <span class="per-night-text">per night</span></span
              >
            </div>
            <div v-else class="error-text-map">
              <v-icon color="primary" size="18">mdi-alert-circle</v-icon>
              <span class="notAvailable-map">Rooms are not available</span>
            </div>
          </div>
        </div>
      </v-card>
    </div>
    <div v-else class="display-hotel-list" @click="!disable && handleRoom({}, '')">
      <v-card width="100%" height="275" color="#fff" theme="dark" class="rounded-card">
        <div class="display-card" @mouseover="handleHover">
          <div class="photo">
            <v-carousel height="238" hide-delimiters style="carousel-view">
              <v-carousel-item v-for="(url, i) in hotelsImage" :key="i" cover>
                <v-sheet height="100%" width="400">
                  <v-img class="hotel-image" :src="url" cover max-height="400" height="100vh" />
                </v-sheet>
              </v-carousel-item>
            </v-carousel>
          </div>
          <div style="width: 100%; padding-right: 20px">
            <div class="hotel-heading">{{ hotelName }}</div>
            <div class="single-rating-text">
              {{ hotel.rating }}<v-icon color="#f0f0f0" size="18">mdi-star</v-icon>
            </div>
            <div>
              <v-icon class="location-icon">mdi-map-marker</v-icon>
              <span class="location-text"
                >{{ hotel.streetAddress }}, {{ hotel.city }}, {{ hotel.country }}</span
              >
            </div>
            <!-- <div class="d-flex" style="margin-top: 5px;">
          <v-rating
            v-model="hotel.rating"
            color="#FFC338"
            hover
            half-increments
            density="compact"
            background-color="#FFC338"
            size="18"
          ></v-rating>
          <div class="rating-text">{{ hotel.rating }}</div>
        </div> -->
            <v-flex class="pourpose" d-flex>
              <div v-for="(n, index) in hotel.type" :key="index" style="margin-inline-end: 20px">
                <div class="pourpose-text">
                  <v-icon class="pourpose-icon">mdi-check-circle-outline</v-icon>
                  {{ n }}
                </div>
              </div>
            </v-flex>
            <div class="free-cancel-text">
              <v-icon class="free-cancel-icon">mdi-cancel</v-icon> Free cancellation until 24 hours
              of checkin time.
            </div>
            <v-flex class="extra" d-flex>
              <v-icon class="extra-icon">mdi-car-brake-parking</v-icon>
              <v-icon class="extra-icon">mdi-television</v-icon>
              <v-icon class="extra-icon">mdi-bottle-soda-classic-outline</v-icon>
              <v-icon class="extra-icon">mdi-air-conditioner</v-icon>
              <v-icon class="extra-icon">mdi-air-filter</v-icon>
              <v-icon class="extra-icon">mdi-wifi</v-icon>
              <div class="extra-more">+ {{ hotel.amenities.length }} more</div>
            </v-flex>
            <div class="seperate-line" />
            <!-- <div v-if="areRoomsAvailable"> -->
            <div class="time-container">
              <div
                v-bind:class="
                  this.threeHourRate === '--' || !areRoomsAvailable
                    ? 'time-container-disable'
                    : 'time-container-inner'
                "
                @click="threeHourRate !== '--' && handleRoom(threeHourRate, '3h')"
              >
                <div class="price-text">
                  {{ this.threeHourRate !== '--' && !!currency ? currency.shortName : ''
                  }}{{
                    this.threeHourRate !== '--' && !!currency
                      ? Math.ceil(this.threeHourRate * currency.exchangeRate)
                      : this.threeHourRate
                  }}
                </div>
                <span>3 hours</span>
              </div>
              <div
                v-bind:class="
                  this.sixHourRate === '--' || !areRoomsAvailable
                    ? 'time-container-disable'
                    : 'time-container-inner'
                "
                @click="sixHourRate !== '--' && handleRoom(sixHourRate, '6h')"
              >
                <div class="price-text">
                  {{ this.sixHourRate !== '--' && !!currency ? currency.shortName : ''
                  }}{{
                    this.sixHourRate !== '--' && !!currency
                      ? Math.ceil(this.sixHourRate * currency.exchangeRate)
                      : this.sixHourRate
                  }}
                </div>
                <span>6 hours</span>
              </div>
              <div
                v-bind:class="
                  this.twelveHourRate === '--' || !areRoomsAvailable
                    ? 'time-container-disable'
                    : 'time-container-inner'
                "
                @click="twelveHourRate !== '--' && handleRoom(twelveHourRate, '12h')"
              >
                <div class="price-text">
                  {{ this.twelveHourRate !== '--' && !!currency ? currency.shortName : ''
                  }}{{
                    this.twelveHourRate !== '--' && !!currency
                      ? Math.ceil(this.twelveHourRate * currency.exchangeRate)
                      : this.twelveHourRate
                  }}
                </div>
                <span>12 hours</span>
              </div>
            </div>
            <div v-if="hotel.minimumOverNightPrice && !!currency" class="overnight-text">
              Overnight rate from {{ currency.shortName
              }}{{
                hotel.minimumOverNightPrice
                  ? Math.ceil(hotel.minimumOverNightPrice * currency.exchangeRate)
                  : hotel.minimumOverNightPrice
              }}
            </div>
            <div v-else class="overnight-text">Overnight rate is N/A</div>
            <!-- </div>
        <div v-else class="error-text" style="marginTop: 20px; paddingLeft: 10px">
          <v-icon color="primary" size="16">mdi-alert-circle</v-icon>
          <span class="notAvailable-list">Rooms are not available</span>
        </div> -->
          </div>
        </div>
      </v-card>
      <!-- <div v-else>
    <v-card width="250" height="310" style="marginBottom: 10px; background: rgba(0,0,0,0.1)">
          <v-skeleton-loader
          class="mx-auto border"
          max-width="300"
          max-height="310"
          min-height="150"
          type="image, article"
        ></v-skeleton-loader>
    </v-card>
    </div> -->
    </div>
    <div class="display-hotel">
      <v-card width="250" height="310" class="rounded-card">
        <v-img class="hotel-image" v-if="hotelsImage" height="168px" :src="hotelsImage[0]"></v-img>
        <div class="no-image-card" v-if="!hotelsImage">
          <div>
            <v-img src="@/assets/no-image.svg"></v-img>
          </div>
        </div>
        <div class="card-details">
          <div class="hotel-heading-mobile">{{ hotelName }}</div>
          <div class="d-flex text-center">
            <v-rating
              v-model="hotel.rating"
              color="#FFC338"
              hover
              half-increments
              density="compact"
              background-color="#FFC338"
              size="large"
            ></v-rating>
            <div class="rating-text">{{ hotel.rating }}</div>
          </div>
          <div v-if="areRoomsAvailable && !!currency" class="hotel-rate-container">
            <span class="hotel-rate"
              >From <span> {{ currency.shortName }}{{ hotelRate }}</span></span
            >
            <span class="overnight-rate"
              ><strike> {{ currency.shortName }}{{ overnightRate }}</strike>
              <span class="per-night-text">per night</span></span
            >
          </div>
          <div v-else class="error-text">
            <v-icon color="primary" size="18">mdi-alert-circle</v-icon>
            <span class="notAvailable">Rooms are not available</span>
          </div>
        </div>
        <div class="ratings" v-if="avgRating > 0">
          <div class="category-rating">
            {{ avgRating }}<br />
            {{ avgRatingType }}
          </div>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import Lodash from 'lodash';
import { RATINGS_TYPE } from '@/constants';
import Carousel from '@/common/ViewHotelCarousel.vue';

export default {
  name: 'HotelCard',
  data() {
    return {
      hotelName: '',
      hotelRate: '',
      overnightRate: '',
      hotelsImage: '',
      rating: 5,
      avgRating: 0,
      avgRatingType: '',
      coordinates: {},
      threeHourRate: 0,
      sixHourRate: 0,
      twelveHourRate: 0,
      disable: false,
    };
  },
  components: {
    Carousel,
  },
  props: ['hotel', 'cardStyle', 'noCard', 'currency'],
  methods: {
    handleHover() {
      this.$emit('card-hover', this.$props.index);
    },
    handleRoom(price, time) {
      if (time === '3h') {
        const roomSelect = this.hotel.rooms.filter(item => item.threeHourRate === price);
        this.$emit('room-selected', roomSelect[0]._id);
        this.$emit('card-duration', time);
        this.disable = true;
      } else if (time === '6h') {
        const roomSelect = this.hotel.rooms.filter(item => item.sixHourRate === price);
        this.$emit('room-selected', roomSelect[0]._id);
        this.$emit('card-duration', time);
        this.disable = true;
      } else if (time === '12h') {
        const roomSelect = this.hotel.rooms.filter(item => item.twelveHourRate === price);
        this.$emit('room-selected', roomSelect[0]._id);
        this.$emit('card-duration', time);
        this.disable = true;
      } else {
        this.disable = false;
        this.$emit('room-selected', {});
        this.$emit('card-duration', '');
      }
    },
  },
  mounted() {
    this.hotelName = this.hotel.name;
    this.coordinates = this.hotel.location;
    if (this.hotel.rooms.length > 0) {
      this.hotelRate = Lodash.minBy(this.hotel.rooms, 'threeHourRate').threeHourRate;
      this.overnightRate = Lodash.minBy(this.hotel.rooms, 'overnightRate').overnightRate;
      const availableRoomThree = this.hotel.rooms.filter(item => item.isAvailableThree);
      const availableRoomSix = this.hotel.rooms.filter(item => item.isAvailableSix);
      const availableRoomTwelve = this.hotel.rooms.filter(item => item.isAvailableTwelve);

      // Find the item with the lowest price among available items
      this.threeHourRate = availableRoomThree.length > 0
        ? _.minBy(availableRoomThree, 'threeHourRate').threeHourRate
        : '--';
      this.sixHourRate = availableRoomSix.length > 0 ? _.minBy(availableRoomSix, 'sixHourRate').sixHourRate : '--';
      this.twelveHourRate = availableRoomTwelve.length > 0
        ? _.minBy(availableRoomTwelve, 'twelveHourRate').twelveHourRate
        : '--';
    }
    if (this.hotel.photos && this.hotel.photos.length) {
      this.hotelsImage = this.hotel.photos;
    }
    if (this.hotel.avgReview) {
      this.avgRating = parseInt(this.hotel.avgReview, 0);
      const rateType = RATINGS_TYPE.filter(data => data.value === parseInt(this.avgRating, 0));
      this.avgRatingType = rateType[0].type;
    }
  },
  computed: {
    areRoomsAvailable() {
      return Lodash.some(this.hotel.rooms, room => room.isAvailable);
    },
  },
};
</script>

<style lang="scss" scoped>
.error-text {
  width: 65%;
  color: $error-color;
  font-size: 14px;
  background: rgba(230, 58, 60, 0.1);
  border-radius: 4px;
  padding: 5px 0px;
  align-items: center;
  position: absolute;
  bottom: 15px;
  span {
    margin-left: 10px;
    position: relative;
    top: 1.5px;
  }
}

.error-text-map {
  width: 48%;
  color: $error-color;
  font-size: 15px;
  background: rgba(230, 58, 60, 0.1);
  border-radius: 4px;
  padding: 5px 3px;
  align-items: center;
  position: absolute;
  bottom: 5px;
  span {
    margin-left: 5px;
    position: relative;
    top: 1.5px;
  }
  .notAvailable-map {
    font-size: 14px;
  }
}

.hotel-card-container {
  width: 98%;
  .per-night-text {
    font-size: 12px;
  }
  .hotel-rate-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: absolute;
    width: 80%;
    bottom: 5px;
  }

  .hotel-rate-container-map {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 45%;
    position: absolute;
    bottom: 5px;
  }
  .overnight-rate {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    font-size: 16px;
    font-weight: bold;
    color: black;
  }
  .hotel-rate {
    span {
      font-size: 18px;
      font-weight: bold;
      color: $primary-color;
    }
  }
  .v-card {
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.6);
  }
  .hotel-heading {
    white-space: nowrap;
    //width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 700;
    margin: 5px 5px;
    font-size: 20px;
    margin-top: 20px;
  }
  .location-icon {
    font-size: 16px;
    margin-right: 8px;
    margin-left: 5px;
  }
  .location-text {
    font-size: 14px;
  }
  .hotel-heading-map {
    // white-space: nowrap;
    width: 160px;
    // height: 50px;
    // background-color: #ff4500;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: bold;
    font-size: 17px;
    margin: 5px 0;
  }

  .pourpose {
    display: flex;
    margin: 5px 0px;
    //background: #c4c5cc;
    //margin-inline-end: 20px;
    .pourpose-icon {
      font-size: 16px;
      color: #228b22;
    }
    .pourpose-text {
      font-size: 14px;
      margin-left: 5px;
      color: #228b22;
    }
  }

  .extra {
    display: flex;
    margin: 10px 5px;
    .extra-icon {
      font-size: 15px;
      color: rgba(0, 0, 0, 0.87);
      margin-inline-end: 15px;
    }
    .extra-more {
      font-size: 12px;
      color: #808080;
      //font-weight: 700;
      text-align: center;
    }
  }

  .single-rating-text {
    font-size: 16px;
    width: 50px;
    color: #f0f0f0;
    text-align: center;
    background: #ff4500;
    padding: 5px;
    border-radius: 5px 5px 5px 0px;
    position: absolute;
    top: 15px;
    right: 15px;
  }

  .rating-text {
    font-size: 15px;
    //margin-left: 10px;
    text-align: center;
    //margin-top: 3px;
  }
  .rating-text-map {
    font-size: 15px;
    margin-top: 2px;
    margin-left: 5px;
  }
  .single-rating-text-map {
    font-size: 15px;
    width: 50px;
    color: #f0f0f0;
    text-align: center;
    background: #ff4500;
    padding: 2px;
    border-radius: 5px 5px 5px 0px;
  }
  .v-card__title {
    font-size: 18px;
    line-height: 24px;
    padding: 7px 0px;
  }
  .seperate-line {
    width: 100%;
    height: 1px;
    background-color: #ddd;
  }
  .theme--light.v-card > .v-card__text {
    color: rgba(0, 0, 0, 0.87);
  }
  .card-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 10px;
  }
  .no-image-card {
    height: 168px;
    background-color: #c4c5cc;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .new-label {
    position: absolute;
    top: 10px;
    right: 10px;
    height: 24px;
    width: 40px;
    border-radius: 2px;
    background-color: $primary-color;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    font-weight: bold;
  }
  .stars {
    display: flex;
  }
  ::v-deep .v-rating .v-icon {
    padding: unset !important;
  }
  .ratings {
    position: absolute;
    top: 5px;
    right: 5px;
  }
  .category-rating {
    background-color: #ff4500;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70px;
    height: 50px;
    color: #fff;
    font-weight: bold;
    font-size: 15px;
    text-align: center;
  }
}

.notAvailable {
  font-size: 16px;
}
.notAvailable-list {
  font-size: 14px;
}
.time-container {
  display: flex;
  width: 100%;
  margin-top: 10px;
  // background: #808080;
  justify-content: space-evenly;
  .time-container-inner {
    width: 17%;
    height: 12%;
    background: #fff;
    // height: 50px;
    align-items: center;
    text-align: center;
    border-radius: 15px;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.6);
    padding: 2px 5px;
    .price-text {
      font-size: 16px;
      font-weight: 700;
      color: #ff4500;
    }
    span {
      color: #ff4500;
      font-size: 14px;
    }
  }
  .time-container-disable {
    width: 17%;
    background: #f0f0f0;
    // height: 50px;
    align-items: center;
    text-align: center;
    border-radius: 15px;
    height: 12%;
    //box-shadow: 0px 1px 5px rgba(0, 0, 0, .6);
    padding: 2px 5px;
    .price-text {
      font-size: 16px;
      font-weight: 700;
      color: #808080;
    }
    span {
      color: #808080;
      font-size: 14px;
    }
  }
}

.time-container-inner:focus,
.time-container-inner:hover {
  background: #ff4500;
  .price-text {
    color: #fff;
  }
  span {
    color: #fff;
  }
}

.overnight-text {
  //width: 90%;
  height: 25px;
  background: #fef1eb;
  margin: 10px 5px;
  text-align: center;
  border-radius: 5px;
  color: #ff4500;
  font-weight: 700;
  font-size: 13px;
  padding-top: 0.5%;
}

.free-cancel-text {
  color: #228b22;
  font-weight: 500;
  font-size: 14px;
  margin-left: 5px;
}
.free-cancel-icon {
  font-size: 16px;
  color: #228b22;
}

.hover-hotel-list {
  border: 2px solid #fff;
  border-radius: 30px;
  margin-bottom: 15px;
  //width: 95%;
}
.hover-hotel-list:focus,
.hover-hotel-list:hover {
  border: 3px solid #ff4500;
  border-radius: 10px;
  // width: 95%;
}

.rounded-card {
  border-radius: 10px;
  // margin-bottom: 20px;
}

.photo {
  width: 260px;
  margin-right: 10px;
  //height: 320px;
  margin: 10px;
  padding: 8px;
}

.display-card {
  display: flex;
  widows: 100%;
}

.hotel-rate-map {
  span {
    font-size: 18px;
    font-weight: bold;
    color: $primary-color;
  }
}

// .left-container .carousel-container{
//   .carousel-img, .v-carousel, .v-image{
//       height: 400px;
//   }
//   .v-image__image--cover{
//     background-size: auto !important;
//   }
// }

.fill-height .v-image__image {
  background-size: cover !important;
}

.display-hotel-map {
  display: block;
}
.display-hotel-list {
  display: block;
}
.display-hotel {
  display: none;
}

// responsiveness design
@media only screen and (max-width: 768px) {
  .hotel-card-container {
    .v-card {
      width: 165px !important;
      height: 235px !important;
    }
    .hotel-image {
      width: 165px;
      height: 100px !important;
    }
    .hotel-image-map {
      width: 165px;
      height: 105px !important;
      border-radius: 10px;
    }
    .v-card__title {
      font-size: 16px !important;
      padding: 10px 16px !important;
    }
    .hotel-heading {
      width: 150px !important;
    }
    .hotel-rate span {
      font-size: 16px !important;
    }
    .no-image-card {
      width: 162px;
      height: 100px !important;
    }
    .display-card {
      display: initial;
    }
    .photo-map {
      width: 170px;
      height: 320px;
      margin-right: 0px;
    }
    .hotel-heading-map {
      width: 150px;
      margin-left: 7px;
      font-size: 15px;
    }
    .hotel-rate-map {
      margin-left: 10px;
      font-size: 12px;
      span {
        font-size: 16px;
        font-weight: bold;
        color: $primary-color;
      }
    }
    .overnight-rate {
      font-size: 14px;
    }
    .notAvailable {
      font-size: 12px;
    }

    .photo {
      width: 140px;
    }
    .display-hotel-map {
      display: none;
    }
    .display-hotel-list {
      display: none;
    }
    .display-hotel {
      display: block;
    }
    .hotel-heading-mobile {
      font-weight: 700;
    }
    // .hover-hotel-list{
    // display: none;
    // }
  }
  .error-text {
    position: absolute;
    width: 90%;
    bottom: 10px;
  }

  .hover-hotel-list {
    width: 170px;
  }
  .hover-hotel-list:focus,
  .hover-hotel-list:hover {
    width: 170px;
  }
}
</style>
